<template>
  <CContainer class="d-flex align-items-center min-vh-100 justify-content-center">
    <CRow>
      <CCol md="12">
        <CCardGroup>
          <CCard v-if="!successfulReset" class="p-4">
            <CCardBody>
              <CForm v-on:keyup.13="submit">
                <h1>Passwort vergessen?</h1>
                <p class="text-muted">Kein Problem! Geben Sie dazu Ihre bei uns hinterlegte<br>E-Mail Adresse an um Ihr Passwort zurückzusetzen.</p>
                <CInput
                        v-model="email"
                        placeholder="E-Mail (oder Benutzername)"
                        autocomplete="email username"
                >
                  <template #prepend-content>
                    <CIcon name="cil-user"/>
                  </template>
                </CInput>
                <CRow>
                  <CCol col="6">
                    <CButton @click="submit" color="primary" class="px-4">Absenden</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton to="login" color="link" class="px-0">Zurück</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard
              v-else
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
          >
            <CCardBody>
              <CImg src="img/brand/cloud-prt-logo-white.svg" style="width: 20%;margin-bottom: 20px"/>
              <h2>Schauen Sie in Ihr Postfach!</h2>
              <p>Falls Sie keine E-Mail von uns finden, schauen Sie auch in Ihren Spam-Ordner.</p>
              <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                  to="login"
              >
                Zum Login
              </CButton>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from 'axios'
import commons from '../../commons'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: this.$route.params.email || '',
      successfulReset: false
    }
  },
  methods: {
    submit() {
      axios.get('/user/passwordForgot?email=' + this.email)
        .then(response => {
          this.successfulReset = true
          this.$toaster.makeToast('Unterwegs!', 'Wir haben Ihnen eine E-Mail an "' + response.data.email + '" mit einem Link zum Zurücksetzen Ihres Passworts geschickt.')
        }).catch(err => {
          if (err.response.status === 404) {
            this.$toaster.makeToast('Fehler!', 'Dieser Nutzer existiert nicht.')
          } else if (err.response.status === 400) {
            this.$toaster.makeToast('Fehler!', 'Bitte warten Sie 5 Minuten bevor Sie es erneut versuchen.')
          } else {
            this.$toaster.makeToast('Fehler!', err.response.data.message)
          }
      })
    }
  }
}
</script>
